<template>
<div>
<div class="monthWrp">
	<h3>{{dispYM()}}</h3>
	<a class="prev" @click="prevMonth" v-show="checkPrevMonth()">&lt;前の月</a>
	<a class="next" @click="nextMonth">次の月&gt;</a>
</div>
<ul>
	<li>日</li>
	<li>月</li>
	<li>火</li>
	<li>水</li>
	<li>木</li>
	<li>金</li>
	<li>土</li>
	<li v-for="(l, i) in cal">
		<div :class="{active: l.isActive}" @click="selectDate(l)">{{l.d == null ? '　' : l.d.getDate()}}</div>
	</li>
</ul>
</div>
</template>

<style scoped>
.monthWrp{
	position: relative;
}
.monthWrp .prev{
	position: absolute;
	top: 0;
	left: 0;
	cursor: pointer;
	color: #0000cc;
}
.monthWrp .next{
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;
	color: #0000cc;
}
h3{
	display: block;
	text-align: center;
	margin: 20px auto;
}
ul{
	border-right: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
}
li{
	display: block;
	float: left;
	text-align: center;
	width: 14.28%;
	border-left: 1px solid #ccc;
	border-top: 1px solid #ccc;
}
li>div{
	padding: 20px;
	color: #ccc;
}
li>div.active{
	cursor: pointer;
	color: #0000cc;
}
li>div.active:hover{
	color: white;
	background-color: #0000cc;
}


/*smt*/
@media screen and (max-width: 768px){
li>div{
	padding: 20px 0;
}
}
</style>

<script>
export default {
props: {
	nextNav: {type: Function, default: () => {console.log('bbb')}},
},

data: function(){
	return {
		now: null,
		dispDt: null,
		cal: [],
	}
},

mounted: function(){
	this.now = new Date()
	this.dispDt = new Date()
	this.cal = this.calendarData(new Date(this.dispDt.getTime()))
},

methods: {
	selectDate: function(l){
		if(! l.isActive) return false
		this.nextNav('date', l.d)
	},

	prevMonth: function(){
		if(! this.checkPrevMonth()) return false
		let now = new Date(this.now)
		let d = new Date(this.dispDt.getTime())
		d.setMonth(d.getMonth() - 1)
		this.dispDt = d
		this.cal = this.calendarData(new Date(this.dispDt.getTime()))
	},

	checkPrevMonth: function(){
		try{
			let now = new Date(this.now)
			let d = new Date(this.dispDt.getTime())
			d.setMonth(d.getMonth() - 1)
			if(d < now) return false
			return true
		}catch(e){
			return false
		}
	},

	nextMonth: function(){
		let d = new Date(this.dispDt.getTime())
		d.setMonth(d.getMonth() + 1)
		this.dispDt = d
		this.cal = this.calendarData(new Date(this.dispDt.getTime()))
	},

	calendarData: function(dt){
		let d = new Date(dt)
		d.setDate(1)
		let firstDay = new Date(d.getTime())
		d.setMonth(d.getMonth() + 1)
		d.setDate(0)
		let lastDay = new Date(d.getTime())
	
		//月を取得
		const month = firstDay.getMonth() + 1
	
		let cal = []
		//前処理
		for(let i=0, l=firstDay.getDay(); i<l; i++) cal.push({isActive: false, d: null})
	
		//メイン
		d = new Date(dt)
		let now = new Date(this.now)
		for(let i=1, l=lastDay.getDate(); i<=l; i++){
			d.setDate(i)
			if(d < now){
				cal.push({isActive: false, d: new Date(d.getTime())})
			}else{
				cal.push({isActive: true, d: new Date(d.getTime())})
			}
		}
	
		//後処理
		for(let i=0, l=6 - lastDay.getDay(); i<l; i++) cal.push({isActive: false, d: null})

		return cal
	},

	dispYM: function(){
		try{
			let d = new Date(this.dispDt.getTime())
			let y = d.getFullYear()
			let m = d.getMonth() + 1
			return y + '年' + m + '月'
		}catch(e){
			return ''
		}
	},

},
}
</script>
