import Header from '../../components/Header.vue'
import Menu from '../../components/Menu.vue'
import Person from './Person.vue'
import Calendar from './Calendar.vue'
import Time from './Time.vue'

export default{
	data: function(){
		return {
			recentlies: [],
			searched: [],
			word: '',
			page: 'person',
			isSearched: false,
			isModalConfirm: false,
			navPerson: {
				selected: false,
				person: {},
			},
			navDate: {
				selected: false,
				text: '日付を選択',
			},
			navTime: {
				selected: false,
				text: '時間を選択',
			},
		}
	},

	mounted: async function(){
		this.recentlies = [
			{id: 1, name: '山田　太郎医師', group: '東京病院', profile: "大阪医科大学　臨床教育教授(2017年4月1日～)\n日本整形外科学会専門医\n　・リウマチ認定医\n　・スポーツ認定医\n日本手外科学会専門医\n日本リハビリテーション医学会専門医\n整形外科専門医として整形関連の病気と外傷は幅広く診療に携わってきました。専門は手の外科、肘関節の外科で、この領域は深く掘り下げて診断と手術を行っています。小回りの利く小規模病院の特長を生かし、日帰り手術を多く取り扱っています。患者さん目線がモットーです。"},
			{id: 2, name: '山田　太郎医師', group: '東京病院', profile: "大阪医科大学　臨床教育教授(2017年4月1日～)\n日本整形外科学会専門医\n　・リウマチ認定医\n　・スポーツ認定医\n日本手外科学会専門医\n日本リハビリテーション医学会専門医\n整形外科専門医として整形関連の病気と外傷は幅広く診療に携わってきました。専門は手の外科、肘関節の外科で、この領域は深く掘り下げて診断と手術を行っています。小回りの利く小規模病院の特長を生かし、日帰り手術を多く取り扱っています。患者さん目線がモットーです。"},
			{id: 3, name: '山田　太郎医師', group: '東京病院', profile: "大阪医科大学　臨床教育教授(2017年4月1日～)\n日本整形外科学会専門医\n　・リウマチ認定医\n　・スポーツ認定医\n日本手外科学会専門医\n日本リハビリテーション医学会専門医\n整形外科専門医として整形関連の病気と外傷は幅広く診療に携わってきました。専門は手の外科、肘関節の外科で、この領域は深く掘り下げて診断と手術を行っています。小回りの利く小規模病院の特長を生かし、日帰り手術を多く取り扱っています。患者さん目線がモットーです。"},
		]
	},

	methods: {
		changePerson: function(){
			if(! this.navPerson.selected) return false
			this.navDate.selected = false
			this.navTime.selected = false
			this.page = 'person'
		},

		changeDate: function(){
			if(! this.navDate.selected) return false
			this.navDate.selected = false
			this.navTime.selected = false
			this.page = 'date'
		},

		nextNav: function(nav, selected){
			if(nav == 'person'){
				this.navPerson.selected = true
				this.navPerson.person = selected
				this.isSearched = false
				this.word = ''
				this.page = 'date'
			}else if(nav == 'date'){
				this.navDate.selected = true
				this.navDate.text = selected
				this.page = 'time'
			}else if(nav == 'time'){
				this.navTime.selected = true
				this.navTime.text = selected
				this.isModalConfirm = true
			}
		},

		toText: function(d){
			try{
				const y = d.getFullYear()
				const m = d.getMonth() + 1
				const dd = d.getDate()
				return y + '年' + m + '月' + dd + '日'
			}catch(e){
				return ''
			}
		},

		reserve: async function(){
			this.$router.push({name: 'Done'})
		},

		search: async function(){
			this.isSearched = true
			this.searched = [
				{id: 1, name: '山田　太郎医師', group: '東京病院', profile: "大阪医科大学　臨床教育教授(2017年4月1日～)\n日本整形外科学会専門医\n　・リウマチ認定医\n　・スポーツ認定医\n日本手外科学会専門医\n日本リハビリテーション医学会専門医\n整形外科専門医として整形関連の病気と外傷は幅広く診療に携わってきました。専門は手の外科、肘関節の外科で、この領域は深く掘り下げて診断と手術を行っています。小回りの利く小規模病院の特長を生かし、日帰り手術を多く取り扱っています。患者さん目線がモットーです。"},
				{id: 2, name: '山田　太郎医師', group: '東京病院', profile: "大阪医科大学　臨床教育教授(2017年4月1日～)\n日本整形外科学会専門医\n　・リウマチ認定医\n　・スポーツ認定医\n日本手外科学会専門医\n日本リハビリテーション医学会専門医\n整形外科専門医として整形関連の病気と外傷は幅広く診療に携わってきました。専門は手の外科、肘関節の外科で、この領域は深く掘り下げて診断と手術を行っています。小回りの利く小規模病院の特長を生かし、日帰り手術を多く取り扱っています。患者さん目線がモットーです。"},
				{id: 3, name: '山田　太郎医師', group: '東京病院', profile: "大阪医科大学　臨床教育教授(2017年4月1日～)\n日本整形外科学会専門医\n　・リウマチ認定医\n　・スポーツ認定医\n日本手外科学会専門医\n日本リハビリテーション医学会専門医\n整形外科専門医として整形関連の病気と外傷は幅広く診療に携わってきました。専門は手の外科、肘関節の外科で、この領域は深く掘り下げて診断と手術を行っています。小回りの利く小規模病院の特長を生かし、日帰り手術を多く取り扱っています。患者さん目線がモットーです。"},
				{id: 4, name: '山田　太郎医師', group: '東京病院', profile: "大阪医科大学　臨床教育教授(2017年4月1日～)\n日本整形外科学会専門医\n　・リウマチ認定医\n　・スポーツ認定医\n日本手外科学会専門医\n日本リハビリテーション医学会専門医\n整形外科専門医として整形関連の病気と外傷は幅広く診療に携わってきました。専門は手の外科、肘関節の外科で、この領域は深く掘り下げて診断と手術を行っています。小回りの利く小規模病院の特長を生かし、日帰り手術を多く取り扱っています。患者さん目線がモットーです。"},
				{id: 1, name: '山田　太郎医師', group: '東京病院', profile: "大阪医科大学　臨床教育教授(2017年4月1日～)\n日本整形外科学会専門医\n　・リウマチ認定医\n　・スポーツ認定医\n日本手外科学会専門医\n日本リハビリテーション医学会専門医\n整形外科専門医として整形関連の病気と外傷は幅広く診療に携わってきました。専門は手の外科、肘関節の外科で、この領域は深く掘り下げて診断と手術を行っています。小回りの利く小規模病院の特長を生かし、日帰り手術を多く取り扱っています。患者さん目線がモットーです。"},
				{id: 2, name: '山田　太郎医師', group: '東京病院', profile: "大阪医科大学　臨床教育教授(2017年4月1日～)\n日本整形外科学会専門医\n　・リウマチ認定医\n　・スポーツ認定医\n日本手外科学会専門医\n日本リハビリテーション医学会専門医\n整形外科専門医として整形関連の病気と外傷は幅広く診療に携わってきました。専門は手の外科、肘関節の外科で、この領域は深く掘り下げて診断と手術を行っています。小回りの利く小規模病院の特長を生かし、日帰り手術を多く取り扱っています。患者さん目線がモットーです。"},
				{id: 3, name: '山田　太郎医師', group: '東京病院', profile: "大阪医科大学　臨床教育教授(2017年4月1日～)\n日本整形外科学会専門医\n　・リウマチ認定医\n　・スポーツ認定医\n日本手外科学会専門医\n日本リハビリテーション医学会専門医\n整形外科専門医として整形関連の病気と外傷は幅広く診療に携わってきました。専門は手の外科、肘関節の外科で、この領域は深く掘り下げて診断と手術を行っています。小回りの利く小規模病院の特長を生かし、日帰り手術を多く取り扱っています。患者さん目線がモットーです。"},
				{id: 4, name: '山田　太郎医師', group: '東京病院', profile: "大阪医科大学　臨床教育教授(2017年4月1日～)\n日本整形外科学会専門医\n　・リウマチ認定医\n　・スポーツ認定医\n日本手外科学会専門医\n日本リハビリテーション医学会専門医\n整形外科専門医として整形関連の病気と外傷は幅広く診療に携わってきました。専門は手の外科、肘関節の外科で、この領域は深く掘り下げて診断と手術を行っています。小回りの利く小規模病院の特長を生かし、日帰り手術を多く取り扱っています。患者さん目線がモットーです。"},
				{id: 1, name: '山田　太郎医師', group: '東京病院', profile: "大阪医科大学　臨床教育教授(2017年4月1日～)\n日本整形外科学会専門医\n　・リウマチ認定医\n　・スポーツ認定医\n日本手外科学会専門医\n日本リハビリテーション医学会専門医\n整形外科専門医として整形関連の病気と外傷は幅広く診療に携わってきました。専門は手の外科、肘関節の外科で、この領域は深く掘り下げて診断と手術を行っています。小回りの利く小規模病院の特長を生かし、日帰り手術を多く取り扱っています。患者さん目線がモットーです。"},
				{id: 2, name: '山田　太郎医師', group: '東京病院', profile: "大阪医科大学　臨床教育教授(2017年4月1日～)\n日本整形外科学会専門医\n　・リウマチ認定医\n　・スポーツ認定医\n日本手外科学会専門医\n日本リハビリテーション医学会専門医\n整形外科専門医として整形関連の病気と外傷は幅広く診療に携わってきました。専門は手の外科、肘関節の外科で、この領域は深く掘り下げて診断と手術を行っています。小回りの利く小規模病院の特長を生かし、日帰り手術を多く取り扱っています。患者さん目線がモットーです。"},
				{id: 3, name: '山田　太郎医師', group: '東京病院', profile: "大阪医科大学　臨床教育教授(2017年4月1日～)\n日本整形外科学会専門医\n　・リウマチ認定医\n　・スポーツ認定医\n日本手外科学会専門医\n日本リハビリテーション医学会専門医\n整形外科専門医として整形関連の病気と外傷は幅広く診療に携わってきました。専門は手の外科、肘関節の外科で、この領域は深く掘り下げて診断と手術を行っています。小回りの利く小規模病院の特長を生かし、日帰り手術を多く取り扱っています。患者さん目線がモットーです。"},
				{id: 4, name: '山田　太郎医師', group: '東京病院', profile: "大阪医科大学　臨床教育教授(2017年4月1日～)\n日本整形外科学会専門医\n　・リウマチ認定医\n　・スポーツ認定医\n日本手外科学会専門医\n日本リハビリテーション医学会専門医\n整形外科専門医として整形関連の病気と外傷は幅広く診療に携わってきました。専門は手の外科、肘関節の外科で、この領域は深く掘り下げて診断と手術を行っています。小回りの利く小規模病院の特長を生かし、日帰り手術を多く取り扱っています。患者さん目線がモットーです。"},
				{id: 1, name: '山田　太郎医師', group: '東京病院', profile: "大阪医科大学　臨床教育教授(2017年4月1日～)\n日本整形外科学会専門医\n　・リウマチ認定医\n　・スポーツ認定医\n日本手外科学会専門医\n日本リハビリテーション医学会専門医\n整形外科専門医として整形関連の病気と外傷は幅広く診療に携わってきました。専門は手の外科、肘関節の外科で、この領域は深く掘り下げて診断と手術を行っています。小回りの利く小規模病院の特長を生かし、日帰り手術を多く取り扱っています。患者さん目線がモットーです。"},
				{id: 2, name: '山田　太郎医師', group: '東京病院', profile: "大阪医科大学　臨床教育教授(2017年4月1日～)\n日本整形外科学会専門医\n　・リウマチ認定医\n　・スポーツ認定医\n日本手外科学会専門医\n日本リハビリテーション医学会専門医\n整形外科専門医として整形関連の病気と外傷は幅広く診療に携わってきました。専門は手の外科、肘関節の外科で、この領域は深く掘り下げて診断と手術を行っています。小回りの利く小規模病院の特長を生かし、日帰り手術を多く取り扱っています。患者さん目線がモットーです。"},
				{id: 3, name: '山田　太郎医師', group: '東京病院', profile: "大阪医科大学　臨床教育教授(2017年4月1日～)\n日本整形外科学会専門医\n　・リウマチ認定医\n　・スポーツ認定医\n日本手外科学会専門医\n日本リハビリテーション医学会専門医\n整形外科専門医として整形関連の病気と外傷は幅広く診療に携わってきました。専門は手の外科、肘関節の外科で、この領域は深く掘り下げて診断と手術を行っています。小回りの利く小規模病院の特長を生かし、日帰り手術を多く取り扱っています。患者さん目線がモットーです。"},
				{id: 4, name: '山田　太郎医師', group: '東京病院', profile: "大阪医科大学　臨床教育教授(2017年4月1日～)\n日本整形外科学会専門医\n　・リウマチ認定医\n　・スポーツ認定医\n日本手外科学会専門医\n日本リハビリテーション医学会専門医\n整形外科専門医として整形関連の病気と外傷は幅広く診療に携わってきました。専門は手の外科、肘関節の外科で、この領域は深く掘り下げて診断と手術を行っています。小回りの利く小規模病院の特長を生かし、日帰り手術を多く取り扱っています。患者さん目線がモットーです。"},
			]
		},

	},

	components: {
		Header,
		Menu,
		Person,
		Calendar,
		Time,
	},
}
