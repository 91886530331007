import QRCode from 'qrcode'
import Header from '../../components/Header.vue'
import Menu from '../../components/Menu.vue'

export default {
	data: function(){
		return {
			message: '',
			qrText: '183kdkeir2003kl4jrjio3489032904222444keei42890jkek33848ikei392938dh3u39',
		}
	},

	mounted: async function(){
		QRCode.toCanvas(this.$refs.qr, this.qrText, {
			width: 250,
		}, (err) => {
		})

	},

	methods: {
	},

	components: {
		Menu,
		Header,
	},

}
