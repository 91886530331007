<template>
<div class="wrp">
<div class="contents">
	<img class="logo" @click="toHome" src="/images/logo@2.png">
	<p class="me" @click="$router.push({name: 'Account'})">こんにちはxxxさん</p>
</div><!--contents-->
</div>
</template>

<style scoped>
.wrp{
	height: 80px;
	border-bottom: 1px solid var(--col-main);
}
.contents{
	max-width: 1024px;
	margin: 0 auto;
	position: relative;
}
.logo{
	height: 80px;
	cursor: pointer;
}
.me{
	position: absolute;
	top: 0;
	bottom: 0;
	right: 20px;
	margin: auto;
	height: 20px;
	cursor: pointer;
}

@media screen and (max-width: 768px){
.wrp{
	height: 40px;
}
.logo{
	height: 40px;
	}
.me{
	font-size: 12px;
}
}
</style>

<script>
export default{
methods:{
	toHome: function(){
		if(this.$route.name == 'Home') return false
		this.$router.push({name: 'Home'})
	},
},
}
</script>
