import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home/Home.vue'
import Reserve from '../views/Reserve/Reserve.vue'
import Account from '../views/Account/Account.vue'
import Rtc from '../views/Rtc/Rtc.vue'

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/reserve',
    name: 'Reserve',
		component: Reserve,
  },
  {
    path: '/account',
    name: 'Account',
		component: Account,
  },
  {
		path: '/done',
    name: 'Done',
    component: Home,
  },
  {
		path: '/cancel',
    name: 'Cancel',
    component: Home,
  },
  {
		path: '/rtc',
    name: 'Rtc',
    component: Rtc,
  },
  {
		path: '/rtc/:peer_id',
    name: 'Rtc2',
    component: Rtc,
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
