<template>
<div class="wrp">
<div class="border">
<ul>
<li @click="$router.push({name:'Reserve'})">
	<img :src="'/images/menu_reserve' + ($route.name == 'Reserve' ? '' : '_off') + '@2.png'">
	<p>reserve</p>
</li>
<li class="home" @click="$router.push({name: 'Home'})">
	<img :src="'/images/menu_home' + ($route.name == 'Home' ? '' : '_off') + '@2.png'">
	<p>home</p>
</li>
<li @click="$router.push({name: 'Account'})">
	<img :src="'/images/menu_acc' + ($route.name == 'Account' ? '' : '_off') + '@2.png'">
	<p>account</p>
</li>
</ul>
</div><!--border-->
</div><!--wrp-->
</template>

<style scoped>
.wrp{
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100px;
}
.border{
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	background-color: white;
	height: 80px;
	margin: 0 auto;
	overflow: visible;
	border-top: 1px solid #ccc;
}
ul{
	width: 355px;
	height: 100%;
	margin: 0 auto;
	overflow: visible;
}
li{
	width: 70px;
	margin: 0 24px;
	float: left;
	text-align: center;
	height: 100%;
	overflow: visible;
	cursor: pointer;
}
li img{
	display: block;
	margin: 0 auto;
	width: 40px;
	height: 40px;
/*	border: 1px solid #ff0000;*/
	border-radius: 20px;
}
li p{
	font-size: 10px;
	height: 18px;
	line-height: 18px;
}
li.home img{
	width: 60px;
	height: 60px;
	top: -20px;
	border-radius: 30px;
}
li.home p{
	top: -20px;
}


</style>

<script>
export default {
}
</script>
