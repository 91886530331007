<div>
<Header />

<div class="main">
	<h3>アカウント設定</h3>

	<canvas class="qr" ref="qr"></canvas>

</div><!--main-->

<div class="menu">
	<Menu />
</div>

</div>
