<div>
<Header />

<div class="main">
	<!--div class="mention" v-show="mention != ''">
		<p>{{mention}}</p>
	</div--><!--mention-->

	<h3>予約状況</h3>
	<ul>
		<li v-for="(l, i) in list"><Item :d="l" :cb="cancelCb" /></li>
	</ul>

</div><!--main-->

<div class="fixed bottom">
	<a class="btn" @click="$router.push({name: 'Reserve'})">予約する</a>
</div><!--fixed bottom-->

<div class="fixed message" :class="{show: message != ''}">
	<p>{{message}}</p>
</div><!--fixed message-->

<div class="menu">
	<Menu />
</div>

</div>
