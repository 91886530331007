<template>
<div>

<p class="m" @click="isModalOpen = true">{{d.name}}</p>

<div class="modal" v-show="isModalOpen">
	<div class="wrp">
		<p class="name">{{d.name}}</p>
		<img class="pic" :src="'/images/sample' + d.id + '.jpg'">
		<p class="group">{{d.group}}</p>
		<pre class="profile">{{d.profile}}</pre>
	</div><!--wrp-->

	<div class="btns">
		<a class="btn sub" @click="isModalOpen = false">戻る</a>
		<a class="btn" @click="selectPerson">選択する</a>
	</div>
</div><!--modal-->

</div>
</template>

<style scoped>
.m{
	color: #0000cc;
	cursor: pointer;
	padding: 20px;
}
.m:hover{
	color: white;
	background-color: #0000cc;
}
.modal{
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.8);
	z-index: 100;
}
.modal .wrp{
	position: absolute;
	width: calc(100% - 40px);
	max-width: 1024px;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	height: calc(100% - 40px);
	/*max-height: 600px;*/
	background-color: white;
	overflow-y: auto;
	padding: 20px;
	padding-bottom: 90px;
}
.name{
	font-weight: bold;
	text-align: left;
}
.pic{
	position: absolute;
	top: 20px;
	right: 20px;
	width: 250px;
	height: 250px;
	object-fit: cover;
}
.group{
	margin: 20px 280px 20px 0;
	text-align: left;
}
.profile{
	margin: 20px 280px 20px 0;
	text-align: left;
}
.modal .btns{
	position: fixed;
	bottom: 20px;
	left: 0;
	right: 0;
	height: 90px;
	text-align: center;
}
.modal .btns .btn{
	display: inline-block;
	min-width: 120px;
	margin: 20px;
}


/*smt*/
@media screen and (max-width: 768px){
.m{
	padding: 20px 10px;
	background-color: #f0f0f0;
	border-radius: 10px;
	margin-bottom: 10px;
}
.name{
	font-weight: bold;
	text-align: left;
}
.pic{
	position: relative;
	display: block;
	top: 0;
	right: 0;
	width: 250px;
	height: 250px;
	object-fit: cover;
	margin: 10px auto;
}
.group{
	margin: 20px 0;
	text-align: left;
}
.profile{
	margin: 20px 0;
	text-align: left;
}
}
</style>

<script>
export default{
props: {
	id: {type: Number, default: 1},
	d: {type: Object, default: () => {return {} }},
	nextNav: {type: Function, default: (n) => {console.log('aaa')}},
},

data: function(){
	return {
		isModalOpen: false
	}
},
methods: {
	selectPerson: function(){
		this.nextNav('person', this.d)
		this.isModalOpen = false
	},
},
}
</script>

